'use strict';

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#videoModal').length !== 0) {
        $('#videoModal').remove();
    }
    var htmlString =
		'<!-- Modal -->' +
		'<div class="modal fade" id="videoModal" role="dialog">' +
		'<span class="enter-message sr-only" ></span>' +
		'<div class="modal-dialog video-dialog">' +
		'<!-- Modal content-->' +
		'<div class="modal-content">' +
		'<div class="modal-header">' +
		'    <button type="button" class="close pull-right" data-dismiss="modal">' +
		'        <span aria-hidden="true">&times;</span>' +
		'        <span class="sr-only"> </span>' +
		'    </button>' +
		'</div>' +
		'<div class="modal-body"></div>' +
		'</div>' +
		'</div>' +
		'</div>';
    $('body').append(htmlString);
}

module.exports = function () {
    $('.youtube').each(function () {
        // Based on the YouTube ID, we can easily find the thumbnail image
        $(this).css(
            'background-image',
            'url(http://i.ytimg.com/vi/' + this.id + '/sddefault.jpg)'
        );
        $(this).append($('<div/>', { class: 'play' }));

        $(document).delegate('#' + this.id, 'click', function () {
            var iframeUrl =
				'https://www.youtube.com/embed/' +
				this.id +
				'?autoplay=1&autohide=1&rel=0';
            if ($(this).data('params')) iframeUrl += '&' + $(this).data('params');
            getModalHtmlElement();
            var iframe = $('<iframe/>', {
                frameborder: '0',
                src: iframeUrl,
                width: '100%',
                height: '100%',
            });
            $('#videoModal .modal-body').html(iframe);
            // show modal
            $('#videoModal').modal('show');
        });
    });

    $(document).on('hidden.bs.modal', '#videoModal', function () {
        $('#videoModal iframe').attr('src', '');
    });

    // handles the pdp page youtube model on video link
    $('body').on('triggerVideoModalPdp', function (e, data) {
        var iframeUrl = 'https://www.youtube.com/embed/' + data.videoId;
        if (data.params) {
            if (iframeUrl.indexOf('?') > -1) {
                iframeUrl += '&autoplay=1&autohide=1&rel=0';
            } else {
                iframeUrl += '?autoplay=1&autohide=1&rel=0';
            }
            iframeUrl += '&' + data.params;
            getModalHtmlElement();
            var iframe = $('<iframe/>', {
                frameborder: '0',
                src: iframeUrl,
                width: '100%',
                height: '100%',
            });
            $('#videoModal .modal-body').html(iframe);
            // show modal
            $('#videoModal').modal('show');
        }
    });
};
