'use strict';

// eslint-disable-next-line strict
var Swiper = require('swiper/bundle'); // .default;// .esm.browser.bundle

// var defaultBreakPoints = {
//     breakpoints: {
//         // when window width is >= 320px
//         320: {
//             slidesPerView: 1,
//             spaceBetween: 20,
//         },
//         // when window width is >= 640px
//         640: {
//             slidesPerView: 3,
//             spaceBetween: 40,
//         },
//     },
// };

/**
 * function used to create slider for explore our boxes section
 */
function initOuterBanner() {
    var $sliderDOMElm = $('.outer-banner .swiper');
    if ($sliderDOMElm.length > 0) {
        var swiperInitialState = {
            pagination: {
                el: '.outer-banner .swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                },
            },
        };

		new Swiper.default('.outer-banner  .swiper', swiperInitialState); // eslint-disable-line
    }
}

module.exports = {
    initOuterBanner,
};
