'use strict';

var toastr = require('toastr');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the wishlist
 * @param {string} buttonToShow - button to show after the ajax call
 */
function displayMessageAndChangeIcon(data, button, buttonToShow) {
    $.spinner().stop();
    toastr.clear();
    if (data.success) {
        toastr.success(data.msg);
        if (buttonToShow === 'showadd') {
            $(button).find('.js-whislist-not-added').removeClass('d-none');
            $(button).find('.js-whislist-added').addClass('d-none');
        } else {
            $(button).find('.js-whislist-not-added').addClass('d-none');
            $(button).find('.js-whislist-added').removeClass('d-none');
        }
    } else {
        toastr.error(data.msg);
    }
    setTimeout(function () {
        button.removeAttr('disabled');
    }, 2000);
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            var button = $(this);

            if ($(this).hasClass('js-remove-from-wishlist')) {
                var urlRemove = $(this).data('remove');
                $(this).attr('disabled', true);
                $.spinner().start();
                $.ajax({
                    url: urlRemove,
                    type: 'get',
                    dataType: 'json',
                    data: {},
                    success: function (data) {
                        $(button).removeClass('js-remove-from-wishlist');
                        displayMessageAndChangeIcon(data, button, 'showadd');
                    },
                    error: function (err) {
                        displayMessageAndChangeIcon(err, button);
                    },
                });
            } else {
                var url = $(this).attr('href');
                var pid = $(this).closest('.product').data('pid');
                var optionId = $(this)
                    .closest('.product-detail')
                    .find('.product-option')
                    .attr('data-option-id');
                var optionVal = $(this)
                    .closest('.product-detail')
                    .find('.options-select option:selected')
                    .attr('data-value-id');
                optionId = optionId || null;
                optionVal = optionVal || null;
                if (!url || !pid) {
                    return;
                }
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: {
                        pid: pid,
                        optionId: optionId,
                        optionVal: optionVal,
                    },
                    success: function (data) {
                        $(button).addClass('js-remove-from-wishlist');
                        displayMessageAndChangeIcon(data, button, 'showremove');
                    },
                    error: function (err) {
                        displayMessageAndChangeIcon(err, button);
                    },
                });
            }
        });
    },
};
