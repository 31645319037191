// eslint-disable-next-line strict
var Swiper = require('swiper/bundle'); // .default;// .esm.browser.bundle
var isMobile = $(window).width() < 768;

// var defaultBreakPoints = {
//     breakpoints: {
//         // when window width is >= 320px
//         320: {
//             slidesPerView: 2,
//             spaceBetween: 20,
//         },
//         // when window width is >= 480px
//         480: {
//             slidesPerView: 3,
//             spaceBetween: 30,
//         },
//         // when window width is >= 640px
//         640: {
//             slidesPerView: 4,
//             spaceBetween: 40,
//         },
//     },
// };

/**
 * function used to create slider for explore our boxes section
 */
function iniTopRibbon() {
    var $sliderDOMElm = $('.topRibbon-Swiper-js .swiper');
    if ($sliderDOMElm.length > 0) {
        var swiperInitialState = {
            loop: $('.topRibbon-Swiper-js .swiper-slide').length !== 1,
            watchOverflow: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.topRibbon-Swiper-js .swiper-pagination',
                type: isMobile ? 'bullets' : 'fraction',
            },
            navigation: {
                nextEl: '.topRibbon-Swiper-js .swiper-button-next',
                prevEl: '.topRibbon-Swiper-js .swiper-button-prev',
            },
        };

		new Swiper.default('.topRibbon-Swiper-js .swiper', swiperInitialState); // eslint-disable-line
    }
}

module.exports = {
    iniTopRibbon: iniTopRibbon,
};
