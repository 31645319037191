var Swiper = require('swiper/bundle');
/**
 * function used to create slider for explore our boxes section
 */
function initCategorySlider() {
    var $sliderDOMElm = $('.category-banner-slider .swiper-slide');
    if ($sliderDOMElm.length > 0) {
        const swiperInitialState = {
            loop: false,
            autoplay: false,
            pagination: false,
            slidesPerView: 2,
            spaceBetween: 20,
            navigation: {
                nextEl: '.category-banner-slider.swiper .swiper-button-next',
                prevEl: '.category-banner-slider.swiper .swiper-button-prev',
            },
            breakpoints: {
                100: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 3,
                },
                1000: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 6,
                },
                1400: {
                    slidesPerView: 7,
                },
            },
        };
		new Swiper.default('.category-banner-slider.swiper', swiperInitialState); // eslint-disable-line
    }
}

/**
 * function used to create slider for wishlist items
 */
function initWishlistSlider() {
    var $sliderDOMElm = $('.js-wishlist-slider.swiper');
    if ($sliderDOMElm.length > 0) {
        const swiperInitialState = {
            loop: false,
            autoplay: false,
            slidesPerView: 3,
            spaceBetween: 20,
            breakpoints: {
                100: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 2,
                },
                1000: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 3,
                },
                1400: {
                    slidesPerView: 3,
                },
            },
            navigation: {
                nextEl: '.js-wishlist-slider .swiper-button-next',
                prevEl: '.js-wishlist-slider .swiper-button-prev',
            },
        };
		new Swiper.default('.js-wishlist-slider.swiper', swiperInitialState); // eslint-disable-line
    }
}

/**
 * function used to create slider for wishlist items
 */
function initOrderHistorySlider() {
    var $sliderDOMElm = $('.account-orderproduct-summary-block-js.swiper');
    if ($sliderDOMElm.length > 0) {
        const swiperInitialState = {
            loop: false,
            autoplay: false,
            slidesPerView: 3,
            spaceBetween: 20,
            breakpoints: {
                100: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 2,
                },
                1000: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 3,
                },
                1400: {
                    slidesPerView: 3,
                },
            },
        };
        new Swiper.default(
            '.account-orderproduct-summary-block-js.swiper',
            swiperInitialState
		); // eslint-disable-line
    }
}

/**
 * function used to create slider for account recently viewed
 */
function accountRecentlyViewdSlider() {
    var $sliderDOMElm = $('.js-account-recommendation-slider.swiper');
    if ($sliderDOMElm.length > 0) {
        const swiperInitialState = {
            loop: false,
            autoplay: false,
            slidesPerView: 3,
            spaceBetween: 20,
            breakpoints: {
                100: {
                    slidesPerView: 2,
                },
                600: {
                    slidesPerView: 3,
                },
                1000: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 4,
                },
                1400: {
                    slidesPerView: 4,
                },
            },
            navigation: {
                nextEl: '.js-account-recommendation-slider .swiper-button-next',
                prevEl: '.js-account-recommendation-slider .swiper-button-prev',
            },
            on: {
                imagesReady: function () {
                    var imageHeight = $(
                        '.js-account-recommendation-slider .swiper-slide .product-tile-image'
                    ).height();
                    $('.js-account-recommendation-slider .swiper-button-next').css({
                        top: imageHeight / 2 + 10,
                        right: '20px',
                        height: '40px',
                        width: '40px',
                    });
                    $('.js-account-recommendation-slider .swiper-button-prev').css({
                        top: imageHeight / 2 + 10,
                        left: '20px',
                        height: '40px',
                        width: '40px',
                    });
                },
            },
        };
        new Swiper.default(
            '.js-account-recommendation-slider.swiper',
            swiperInitialState
		); // eslint-disable-line
    }
}

/**
 * function used to create slider for account recently viewed
 */
function initCalculatorSlider() {
    window.addEventListener('calculatorReady', function () {
        var $sliderDOMElm = $('.js-calculator-recommendation-slider.swiper');
        if ($sliderDOMElm.length > 0) {
            const swiperInitialState = {
                loop: false,
                autoplay: false,
                slidesPerView: 3,
                spaceBetween: 20,
                breakpoints: {
                    100: {
                        slidesPerView: 2,
                    },
                    600: {
                        slidesPerView: 3,
                    },
                    1000: {
                        slidesPerView: 4,
                    },
                    1200: {
                        slidesPerView: 4,
                    },
                    1400: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: '.js-calculator-recommendation-slider .swiper-button-next',
                    prevEl: '.js-calculator-recommendation-slider .swiper-button-prev',
                },
                on: {
                    imagesReady: function () {
                        var imageHeight = $(
                            '.js-calculator-recommendation-slider .swiper-slide .product-tile-image'
                        ).height();
                        $('.js-calculator-recommendation-slider .swiper-button-next').css({
                            top: imageHeight / 2 + 10,
                            right: '20px',
                            height: '40px',
                            width: '40px',
                        });
                        $('.js-calculator-recommendation-slider .swiper-button-prev').css({
                            top: imageHeight / 2 + 10,
                            left: '20px',
                            height: '40px',
                            width: '40px',
                        });
                    },
                },
            };
            new Swiper.default(
                '.js-calculator-recommendation-slider.swiper',
                swiperInitialState
			); // eslint-disable-line
        }
    });
}

module.exports = {
    initCategorySlider: initCategorySlider,
    initWishlistSlider: initWishlistSlider,
    initOrderHistorySlider: initOrderHistorySlider,
    accountRecentlyViewdSlider: accountRecentlyViewdSlider,
    initCalculatorSlider: initCalculatorSlider,
};
