'use strict';

/**
 * Custom validation logic for the registration form.
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid.
 */
function validateRegistrationForm() {
    var valid = true;
    var password = $(this).find('#registration-form-password').val();
    var confirmPassword = $(this)
        .find('#registration-form-password-confirm')
        .val();
    if (password !== confirmPassword) {
        valid = false;
        $(this)
            .find('#registration-form-password-confirm')
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .text('Passwords do not match');
    }

    var email = $(this).find('#registration-form-email').val();
    var confirmEmail = $(this).find('#registration-form-email-confirm').val();
    if (email !== confirmEmail) {
        valid = false;
        $(this)
            .find('#registration-form-email-confirm')
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .text('Emails do not match');
    }

    var termsConditionsChecked = $(this).find('#terms-conditions').is(':checked');
    if (!termsConditionsChecked) {
        valid = false;
        $(this)
            .find('#terms-conditions')
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .text('You must agree to the terms and conditions');
    }

    // InterestedIn validation
    var interestedInField = $(this).find('#registration-form-interestedin');
    if (interestedInField.length > 0) {
        $(this).find('.js-interested-in-section').removeClass('is-invalid');
        var interestedInSelected = interestedInField.find('li.selected').length > 0;
        if (!interestedInSelected) {
            valid = false;
            interestedInField
                .addClass('is-invalid')
                .siblings('.invalid-feedback')
                .text('Please select at least one option.');
        }
    }

    return valid;
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this)
            .find('input, select')
            .each(function () {
                if (!this.validity.valid) {
                    $(this).trigger('invalid', this.validity);
                }
            });
    }

    // Call custom validation for registration form
    if ($(this).hasClass('registration')) {
        valid = validateRegistrationForm.call(this, event) && valid;
    }

    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
}

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if (
                    (this.validity.rangeOverflow || this.validity.rangeUnderflow) &&
					$(this).data('range-error')
                ) {
                    validationMessage = $(this).data('range-error');
                }
                if (
                    (this.validity.tooLong || this.validity.tooShort) &&
					$(this).data('range-error')
                ) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this)
                    .parents('.form-group')
                    .find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },

    submit: function () {
        // we handle customized validation of registration, so we directly called validation on register function
        $('form')
            .not('.registration')
            .on('submit', function (e) {
                return validateForm.call(this, e);
            });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on(
            'click',
            function () {
                // clear all errors when trying to submit the form
                clearForm($(this).parents('form'));
            }
        );
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        isRegistrationValid: function (form, event) {
            return validateForm.call($(form), event || null);
        },
        clearForm: clearForm,
    },
};
