'use strict';

module.exports = {
    handleCountrySelect: function () {
        if (typeof window.Countries !== 'undefined' && window.Countries !== null) {
            $('[name="dwfrm_address_country"]').on('change', function (e) {
                try {
                    e.preventDefault();
                    var selectedCountry = $(this).val();
                    var selectedregions = window.Countries[selectedCountry].regions;
                    var optionsHtml = '<option id="" value=""> </option>';
                    for (var index = 0; index < selectedregions.length; index++) {
                        optionsHtml +=
							'<option id="' +
							selectedregions[index][0] +
							'" value="' +
							selectedregions[index][0] +
							'">' +
							selectedregions[index][1] +
							'</option>';
                    }
                    $('[name="dwfrm_address_states_stateCode"]')
                        .empty()
                        .append(optionsHtml);

                    // logic to check if the selected country do not have postal code requirements
                    var countriesWithNoPostalCode = window.countriesWithNoPostalCode;
                    var selectedCountryHasNoPostalCode =
						countriesWithNoPostalCode[selectedCountry];
                    if (
                        typeof selectedCountryHasNoPostalCode !== 'undefined' &&
						selectedCountryHasNoPostalCode
                    ) {
                        $('[name="dwfrm_address_postalCode"]')
                            .removeAttr('required')
                            .removeClass('required');
                        $('[name="dwfrm_address_postalCode"]')
                            .parents('.form-group')
                            .removeAttr('required')
                            .removeClass('required');
                    } else {
                        $('[name="dwfrm_address_postalCode"]').attr('required', 'required');
                        $('[name="dwfrm_address_postalCode"]')
                            .parents('.form-group')
                            .attr('required', 'required')
                            .addClass('required');
                    }
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.info(err);
                }
            });
            $('[name="dwfrm_shipping_shippingAddress_addressFields_country"]').on(
                'change',
                function (e) {
                    try {
                        e.preventDefault();
                        var selectedCountry = $(this).val();
                        var selectedregions = window.Countries[selectedCountry].regions;
                        var optionsHtml = '<option id="" value=""> </option>';
                        for (var index = 0; index < selectedregions.length; index++) {
                            optionsHtml +=
								'<option id="' +
								selectedregions[index][0] +
								'" value="' +
								selectedregions[index][0] +
								'">' +
								selectedregions[index][1] +
								'</option>';
                        }
                        $(
                            '[name="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"]'
                        )
                            .empty()
                            .append(optionsHtml);

                        // logic to check if the selected country do not have postal code requirements
                        var countriesWithNoPostalCode = window.countriesWithNoPostalCode;
                        var selectedCountryHasNoPostalCode =
							countriesWithNoPostalCode[selectedCountry];
                        if (
                            typeof selectedCountryHasNoPostalCode !== 'undefined' &&
							selectedCountryHasNoPostalCode
                        ) {
                            $(
                                '[name="dwfrm_shipping_shippingAddress_addressFields_postalCode"]'
                            )
                                .removeAttr('required')
                                .removeClass('required');
                            $(
                                '[name="dwfrm_shipping_shippingAddress_addressFields_postalCode"]'
                            )
                                .parents('.form-group')
                                .removeAttr('required')
                                .removeClass('required');
                        } else {
                            $(
                                '[name="dwfrm_shipping_shippingAddress_addressFields_postalCode"]'
                            ).attr('required', 'required');
                            $(
                                '[name="dwfrm_shipping_shippingAddress_addressFields_postalCode"]'
                            )
                                .parents('.form-group')
                                .attr('required', 'required')
                                .addClass('required');
                        }
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.info(err);
                    }
                }
            );
            $('[name="dwfrm_billing_addressFields_country"]').on(
                'change',
                function (e) {
                    try {
                        e.preventDefault();
                        var selectedCountry = $(this).val();
                        var selectedregions = window.Countries[selectedCountry].regions;
                        var optionsHtml = '<option id="" value=""> </option>';
                        for (var index = 0; index < selectedregions.length; index++) {
                            optionsHtml +=
								'<option id="' +
								selectedregions[index][0] +
								'" value="' +
								selectedregions[index][0] +
								'">' +
								selectedregions[index][1] +
								'</option>';
                        }
                        $('[name="dwfrm_billing_addressFields_states_stateCode"]')
                            .empty()
                            .append(optionsHtml);
                        // logic to check if the selected country do not have postal code requirements
                        var countriesWithNoPostalCode = window.countriesWithNoPostalCode;
                        var selectedCountryHasNoPostalCode =
							countriesWithNoPostalCode[selectedCountry];
                        if (
                            typeof selectedCountryHasNoPostalCode !== 'undefined' &&
							selectedCountryHasNoPostalCode
                        ) {
                            $('[name="dwfrm_billing_addressFields_postalCode"]')
                                .removeAttr('required')
                                .removeClass('required');
                            $('[name="dwfrm_billing_addressFields_postalCode"]')
                                .parents('.form-group')
                                .removeAttr('required')
                                .removeClass('required');
                        } else {
                            $('[name="dwfrm_billing_addressFields_postalCode"]').attr(
                                'required',
                                'required'
                            );
                            $('[name="dwfrm_billing_addressFields_postalCode"]')
                                .parents('.form-group')
                                .attr('required', 'required')
                                .addClass('required');
                        }
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.info(err);
                    }
                }
            );
        }
    },
    openChat: function () {
        $(document).ready(function () {
            $('.contactus--support-chat-action').on('click', function () {
                $('.helpButtonEnabled').trigger('click');
            });
        });
    },

    handleBulkOrderPopup: function () {
        $(document).ready(function () {
            $('body').on('click', '.js-bulk-order-popup', function () {
                var subject = $(this).data('subject');
                var bulkMsg = $(this).data('bulk-msg');
                var bulkCallout = $(this).data('bulk-btn-callout');
                $('#bulk-order-popup')
                    .find('.js-bulk-msg-subject')
                    .attr('href', subject);
                $('#bulk-order-popup').find('.js-bulk-popup-msg').html(bulkMsg);
                $('#bulk-order-popup').find('.js-bulk-msg-subject').html(bulkCallout);
                $('#bulk-order-popup').modal({
                    show: true,
                    focus: true,
                });
            });
        });
    },
};
