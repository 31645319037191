'use strict';

module.exports = {
    openGuestCheckout: function () {
        $('body').on('click', '.js-popup-checkout-module-trigger', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            $('.js-guest-checkout-module').addClass('show-module');
            $('#loginPanel').addClass('active');
            $('#registerPanel').removeClass('active');
            $('body').addClass('side-panel-open');
            $('#loginPanel input').first().focus();
            $('html, body').css('overflow', 'hidden');

            if ($('.js-minicart-product-module').length) {
                $('.js-minicart-product-module')
                    .find('.js-module-close-minicart')
                    .trigger('click');
            }
        });
    },
    handleCheckoutLoginForm: function () {
        $('body').on('submit', 'form#registered-customer', function (e) {
            e.preventDefault();
            var form = $(this);
            // var form = $('#registered-customer');
            var url = form.attr('action');
            var recaptchaSiteKey = form.find('button').data('site-key');
            var formData = form.serialize();
            form.spinner().start();
            $('form#registered-customer').trigger('login:submit', e);
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
                // eslint-disable-next-line no-undef
                grecaptcha
                    .execute(recaptchaSiteKey, { action: 'submit' })
                    .then(function (token) {
                        // Add your logic to submit to your backend server here.
                        formData += '&recaptchaToken=' + token;
                        $.ajax({
                            url: url,
                            type: 'post',
                            dataType: 'json',
                            data: formData,
                            success: function (data) {
                                $('#registered-customer .alert.alert-danger').remove();
                                if (data.error) {
                                    form.spinner().stop();
                                    if (data.customerErrorMessage) {
                                        form.prepend(
                                            '<div class="alert alert-danger" role="alert">' +
												data.customerErrorMessage +
												'</div>'
                                        );
                                    }
                                    // eslint-disable-next-line no-param-reassign
                                    data.error = '';
                                    $('#registered-customer').trigger('login:error', data);
                                } else {
                                    $('#registered-customer').trigger('login:success', data);
                                    window.location.href = data.redirectUrl;
                                }
                            },
                            error: function (data) {
                                if (data.responseJSON.redirectUrl) {
                                    window.location.href = data.responseJSON.redirectUrl;
                                } else {
                                    $('#registered-customer').trigger('login:error', data);
                                    form.spinner().stop();
                                }
                            },
                        });
                    });
            });
            return false;
        });
    },
};
