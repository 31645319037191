'use strict';

var cart = require('../cart/cart');

var updateMiniCart = true;
var location = window.location;

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            if (count.quantityTotal === 0) {
                $('.minicart .minicart-quantity').addClass('d-none');
            } else {
                $('.minicart .minicart-quantity').removeClass('d-none');
            }
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems,
            });
        }
    });

    $('body').on('click', '.minicart', function () {
        // if ($('.search:visible').length === 0) {
        //     return;
        // }

        // prevent minicart on cart page
        if ($('.page').attr('data-action') === 'Cart-Show') {
            return;
        }
        var url = $('.minicart').data('action-url');
        var cartQrt = $('.minicart .minicart-quantity').text();
        if (cartQrt === '') {
            return;
        }
        var count = parseInt(cartQrt, 10);

        if (
            !Number.isNaN(count) &&
			count !== 0 &&
			$('.minicart .js-minicart-product-module.show-module').length === 0
        ) {
            $.spinner().start();
            if (!updateMiniCart) {
                $('.minicart .js-minicart-product-module').addClass('show-module');
                $('.minicart .js-minicart-body').addClass('show');
                $.spinner().stop();
                return;
            }

            $('.minicart .js-minicart-body').addClass('show');
            $('.minicart .js-minicart-body').spinner().start();
            $.get(url, function (data) {
                $('.minicart .js-minicart-body').empty();
                $('.minicart .js-minicart-body').append(data);
                $('.minicart .js-minicart-product-module').addClass('show-module');
                $('body, html').addClass('sidepanel-open');
                updateMiniCart = false;
                $('body').trigger('minicart:loadpaypal');
                $.spinner().stop();
            });
        }
    });

    $('body').on('touchstart', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .js-minicart-body').removeClass('show');
        }
    });

    // $('.minicart').on('click', function () {
    //     if ($('.search:visible').length === 0) {
    //         return;
    //     }
    //     var url = $('.minicart').data('action-url');
    //     var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    //     if (count !== 0 && $('.minicart .js-minicart-body.show').length === 0) {
    //         if (!updateMiniCart) {
    //             $('.minicart .js-minicart-body').addClass('show');
    //             return;
    //         }

    //         $('.minicart .js-minicart-body').addClass('show');
    //         $('.minicart .js-minicart-product-module').addClass('show-module');
    //         $('.minicart .js-minicart-body').spinner().start();
    //         $.get(url, function (data) {
    //             $('.minicart .js-minicart-body').empty();
    //             $('.minicart .js-minicart-body').append(data);
    //             $('.minicart .js-minicart-product-module').addClass('show-module');
    //             updateMiniCart = false;
    //             $.spinner().stop();
    //         });
    //     }
    // });
    // $('.minicart').on('mouseleave focusout', function (event) {
    //     if (
    //         (event.type === 'focusout' &&
    // 			$('.minicart').has(event.target).length > 0) ||
    // 		(event.type === 'mouseleave' &&
    // 			$(event.target).is('.minicart .quantity')) ||
    // 		$('body').hasClass('modal-open')
    //     ) {
    //         event.stopPropagation();
    //         return;
    //     }
    //     $('.minicart .popover').removeClass('show');
    // });
    $('body').on('click', '.js-module-close-minicart', function (e) {
        e.stopImmediatePropagation();
        $('.minicart .js-minicart-product-module').removeClass('show-module');
        $('body, html').removeClass('sidepanel-open');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if (
            $(this).parents('.bonus-product-line-item').length &&
			$('.cart-page').length
        ) {
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};
