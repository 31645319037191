'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('base/util');

require('base/thirdParty/bootstrap');
require('base/components/spinner');

$(document).ready(function () {
    processInclude(require('base/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    // processInclude(require('base/components/toolTip'));
    processInclude(require('./components/spinner'));
    processInclude(require('./product/wishlistHeart'));
    processInclude(require('./components/globalLogic'));
    processInclude(require('./components/videoPopup'));
    processInclude(require('./components/topRibbon'));
    processInclude(require('./components/outerBanner'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/slider'));
    processInclude(require('./components/search'));
    processInclude(require('./slider'));
    processInclude(require('./components/quickLogin'));
    processInclude(require('./login/login'));
    processInclude(require('./components/tooltip'));
    processInclude(require('./components/inputQuantity'));
    processInclude(require('./cart/guestCheckout'));
});
