'use strict';

var toastr = require('toastr');

/* eslint-disable radix */
module.exports = function () {
    $(document).on('click', '.js-manual-quantity-plus', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.parent();
        var $input = $parent.find('input');
        var currentValue = parseInt($input.val());
        var futureValue = currentValue + 1;
        if ($input.hasClass('js-show-sale-max')) {
            var maxsaleAllowed = $input.attr('data-max-sale-allowed');
            if (maxsaleAllowed !== null && maxsaleAllowed !== undefined) {
                var maxSale = parseInt(maxsaleAllowed);
                if (futureValue > maxSale) {
                    toastr.remove();
                    toastr.error('Quantity Limited to ' + maxSale);
                } else {
                    $input.val(futureValue);
                    $input.trigger('change');
                }
            } else {
                $input.val(futureValue);
                $input.trigger('change');
            }
        } else {
            $input.val(futureValue);
            $input.trigger('change');
        }
    });

    $(document).on('click', '.js-manual-quantity-minus', function (e) {
        e.preventDefault();
        var $this = $(this);
        var $parent = $this.parent();
        var $input = $parent.find('input');
        var $currentValue = parseInt($input.val());
        var futureValue = $currentValue - 1;
        if (futureValue === 0) {
            return;
        }
        $input.val($currentValue <= 1 ? 1 : $currentValue - 1);
        $input.trigger('change');
    });

    // logic to handle the max quantity in case user enter the number mnaually
    $(document).on('keyup', '.quantity-select', function () {
        var $input = $(this);
        var maxsaleAllowed = $input.attr('data-max-sale-allowed');
        if (maxsaleAllowed !== null && maxsaleAllowed !== undefined) {
            var maxSale = parseInt(maxsaleAllowed);
            var currentValue = parseInt($input.val());
            if (currentValue > maxSale) {
                toastr.remove();
                toastr.error('Quantity Limited to ' + maxSale);
                $input.val(maxSale);
            }
        }
    });
};
