// eslint-disable-next-line strict
var Swiper = require('swiper/bundle'); // .default;// .esm.browser.bundle
var isMobile = $(window).width() < 768;

/**
 * function used to create slider for explore our boxes section
 */
function initChooseYourCraftSlider() {
    var $sliderDOMElm = $('.choose-your-craft-slider');
    if ($sliderDOMElm.length > 0) {
        var swiperInitialState = {
            slidesPerView: 'auto',
            spaceBetween: 20,
            threshold: 5,
            pagination: {
                el: '.choose-your-craft-slider .swiper-pagination',
                type: isMobile ? 'bullets' : 'fraction',
            },
            navigation: {
                nextEl: '.choose-your-craft-slider .swiper-button-next',
                prevEl: '.choose-your-craft-slider .swiper-button-prev',
            },
            scrollbar: {
                el: '.choose-your-craft-slider .swiper-scrollbar',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    centeredSlides: true,
                    loop: true,
                },
                1024: {
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    centeredSlides: false,
                    loop: false,
                },
                1800: {
                    slidesPerView: 4,
                },
            },
        };

		new Swiper.default('.choose-your-craft-slider', swiperInitialState); // eslint-disable-line
    }
}
/**
 * function used to create slider for explore our boxes section
 */
function initLatestProjectstSlider() {
    var $sliderDOMElm = $('.latets-projects-slider');
    if ($sliderDOMElm.length > 0) {
        var swiperInitialState = {
            slidesPerView: 'auto',
            spaceBetween: 20,
            pagination: {
                el: '.latets-projects-slider.swiper-pagination',
                type: isMobile ? 'bullets' : 'fraction',
            },
            navigation: {
                nextEl: '.latets-projects-slider .swiper-button-next',
                prevEl: '.latets-projects-slider .swiper-button-prev',
            },
            scrollbar: {
                el: '.latets-projects-slider .swiper-scrollbar',
            },
            breakpoints: {
                320: {
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    centeredSlides: true,
                    loop: true,
                },
                1024: {
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    centeredSlides: false,
                    loop: false,
                },
                1800: {
                    slidesPerView: 4,
                },
            },
        };

		new Swiper.default('.latets-projects-slider', swiperInitialState); // eslint-disable-line
    }
}

module.exports = {
    initChooseYourCraftSlider,
    initLatestProjectstSlider,
};
