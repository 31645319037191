/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-undef */

'use strict';

var base = require('../product/base');
var focusHelper = require('base/components/focus');
var location = window.location;
var formValidation = require('base/components/formValidation');
var toastr = require('toastr');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl +=
		(newUrl.indexOf('?') !== -1 ? '&' : '?') +
		Object.keys(params)
		    .map(function (key) {
		        return key + '=' + encodeURIComponent(params[key]);
		    })
		    .join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 * @param {string} projectIncomplete - AJAX response from the server
 */
function validateBasket(data, projectIncomplete) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml =
				'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
				'fade show" role="alert">' +
				data.valid.message +
				'</div>';

            $('.cart-error').html('').append(errorHtml);
        } else {
            $('.cart')
                .empty()
                .append(
                    '<div class="row"> ' +
						'<div class="col-12 text-center"> ' +
						'<h1>' +
						data.resources.emptyCartMsg +
						'</h1> ' +
						'</div> ' +
						'</div>'
                );
            $('.cart-header').empty();
            $('.cart-bottom-products').addClass('show');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems,
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        if (projectIncomplete !== undefined && projectIncomplete !== '') {
            var errorHtml1 =
				'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
				'fade show" role="alert">' +
				projectIncomplete +
				'</div>';

            $('.cart-error').html('').append(errorHtml1);
        } else {
            $('.cart-error').html('');
        }
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    // $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    // $('.tax-total').empty().append(data.totals.totalTax);
    // $('.grand-total').empty().append(data.totals.grandTotal);
    $('.total').find('.text-right.value').text(data.totals.grandTotal);
    $('.subtotal').find('.text-right.value').text(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems,
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('d-none');
        $('.order-discount-total')
            .empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('d-none');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('d-none');
        $('.shipping-discount-total')
            .empty()
            .append('- ' + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('d-none');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID)
                .empty()
                .append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price')
            .empty()
            .append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price')
            .empty()
            .append(item.renderedPrice);
        $('.item-total-' + item.UUID)
            .empty()
            .append(item.priceTotal.renderedPrice);
    });

    $('.minicart-footer .js-sub-total').empty().append(data.totals.subTotal);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml =
		'<div class="alert alert-danger alert-dismissible valid-cart-error ' +
		'fade show" role="alert">' +
		'<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
		'<span aria-hidden="true">&times;</span>' +
		'</button>' +
		message +
		'</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html +=
				'<div class="single-approaching-discount text-center">' +
				item.discountMsg +
				'</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages +=
					'<p class="line-item-attributes line-item-instock-date">' +
					lineItem.availability.inStockDate +
					'</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $('.card.product-info.uuid-' + uuid).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString =
		'<!-- Modal -->' +
		'<div class="modal fade top-right" id="editProductModal" tabindex="-1" role="dialog">' +
		'<span class="enter-message sr-only" ></span>' +
		'<div class="modal-dialog quick-view-dialog">' +
		'<!-- Modal content-->' +
		'<div class="modal-content notRounded">' +
		'    <button type="button" class="close pull-right" data-dismiss="modal">' +
		'        <span aria-hidden="true">&times;</span>' +
		'        <span class="sr-only"> </span>' +
		'    </button>' +
		'<div class="modal-body"></div>' +
		'<div class="modal-footer"></div>' +
		'</div>' +
		'</div>' +
		'</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(
                data.closeButtonText
            );
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        },
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.attr('data-pid', productID);
    $deleteConfirmBtn.attr('data-action', actionUrl);
    $deleteConfirmBtn.attr('data-uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {string} shopListItemId - shopListItemId
 */
function confirmSaveForLater(
    actionUrl,
    productID,
    productName,
    uuid,
    shopListItemId
) {
    var $sflConfirmBtn = $('.js-cart-sfl-confirmation-btn');
    var $productToSflSpan = $('.js-product-to-sfl');

    $sflConfirmBtn.data('pid', productID);
    $sflConfirmBtn.data('action', actionUrl);
    $sflConfirmBtn.data('uuid', uuid); // eslint-disable-line no-undef
    $sflConfirmBtn.data('shopListItemId', shopListItemId);

    $productToSflSpan.empty().append(productName);
}

module.exports = function () {
    $('body').on('click', '.remove-product,.delete-project', function (e) {
        e.preventDefault();

        var actionUrl = $(this).attr('data-action');
        var productID = $(this).attr('data-pid');
        var productName = $(this).attr('data-name');
        var uuid = $(this).attr('data-uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });
    $('body').on('click', '.js-saveForLater', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var shopListItemId = $(this).data('shopListItemId');
        confirmSaveForLater(
            actionUrl,
            productID,
            productName,
            uuid,
            shopListItemId
        );
    });

    $('body').on(
        'click',
        '.js-moveAllToCart, .js-move-to-cart, .js-remove-sfl-item',
        function (e) {
            e.preventDefault();
            $.spinner().start();
            var $this = $(this);
            var url = $this.attr('data-href');
            $.ajax({
                url: url,
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    if (data.success && data.redirectURL) {
                        window.location.href = data.redirectURL;
                    } else {
                        window.location.reload();
                    }
                },
                error: function (xhr) {
                    console.error('Error moving product to cart:', xhr.responseText);
                    $.spinner().stop();
                },
            });
        }
    );

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.js-cart-sfl-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var shopListItemId = $(this).data('shopListItemId');
        var qty = $('#quantity-' + uuid).val();
        var urlParams = {
            pid: productID,
            qty: qty,
            uuid: uuid,
            shopListItemId: shopListItemId,
        };

        if (uuid === 'project') {
            var projectData = [];
            $('.project-' + productID + '-lineItem').each(function () {
                var pliData = $(this).find('.js-saveForLater');
                var projectItem = {
                    pid: pliData.data('pid'),
                    uuid: pliData.data('uuid'),
                    shopListItemId: pliData.data('shopListItemId'),
                    qty: $('#quantity-' + pliData.data('uuid')).val(),
                };
                projectData.push(projectItem);
            });
            urlParams.qty = 1;
            urlParams.projectData = JSON.stringify(projectData);
        }

        // url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'post',
            data: urlParams,
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    $.spinner().stop();
                    if (data.showLogin !== undefined && data.showLogin) {
                        $('.js-popup-login-module-trigger').trigger('click');
                        $('form[name="login-form"]').attr('action', data.rurl);
                    }
                    return false;
                }
                // if (data.basket.items.length === 0) {
                //     $('.cart-error').html('');
                //     $('.cart')
                //         .empty()
                //         .append(
                //             '<div class="row"> ' +
                // 				'<div class="col-12 text-center"> ' +
                // 				'<h1>' +
                // 				data.basket.resources.emptyCartMsg +
                // 				'</h1> ' +
                // 				'</div> ' +
                // 				'</div>'
                //         );
                //     $('.cart-header').empty();
                //     $('.cart-bottom-products').addClass('show');
                //     $('.number-of-items')
                //         .empty()
                //         .append(data.basket.resources.numberOfItems);
                //     $('.minicart-quantity').empty().append(data.basket.numItems);
                //     $('.minicart-link').attr({
                //         'aria-label': data.basket.resources.minicartCountOfItems,
                //         title: data.basket.resources.minicartCountOfItems,
                //     });
                //     $('.minicart .popover').empty();
                //     $('.minicart .popover').removeClass('show');
                //     $('body').removeClass('modal-open');
                //     $('html').removeClass('veiled');
                // } else {
                //     if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                //         for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                //             $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                //         }
                //     }
                //     $('.uuid-' + uuid).remove();
                //     if (!data.basket.hasBonusProduct) {
                //         $('.bonus-product').remove();
                //     }
                //     $('.coupons-and-promos')
                //         .empty()
                //         .append(data.basket.totals.discountsHtml);
                //     updateCartTotals(data.basket);
                //     updateApproachingDiscounts(data.basket.approachingDiscounts);
                //     $('body').trigger('setShippingMethodSelection', data.basket);
                //     validateBasket(data.basket);
                // }

                $('body').trigger('cart:update', data);
                window.location.href = data.redirectUrl;

                $.spinner().stop();
                return true;
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).attr('data-pid');
        var url = $(this).attr('data-action');
        var uuid = $(this).attr('data-uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.cart-error').html('');
                if (data.basket.items.length === 0) {
                    $('.cart-error').html('');
                    $('.cart')
                        .empty()
                        .append(
                            '<div class="container cart-empty"><div class="row"> ' +
								'<div class="col-12 text-center"> ' +
								'<h4>' +
								data.basket.resources.emptyCartMsg +
								'</h4> ' +
								'</div> ' +
								'</div></div>'
                        );
                    // $('.number-of-items')
                    //     .empty()
                    //     .append(data.basket.resources.numberOfItems);
                    $('.cart-header').empty();
                    $('.cart-bottom-products').addClass('show');
                    // removing the below line as it doesn't serve any purpose after above 2 lines
                    // $('.page-title').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    if (data.basket.numItems === 0) {
                        $('.minicart-quantity').addClass('d-none');
                    } else {
                        $('.minicart-quantity').removeClass('d-none');
                    }
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems,
                    });
                    // $('.minicart .popover').empty();
                    // $('.minicart .popover').removeClass('show');
                    // $('body').removeClass('modal-open');
                    // $('html').removeClass('veiled');

                    if ($('.js-minicart-product-module').hasClass('show-module')) {
                        $('.js-minicart-product-module')
                            .find('.js-module-close-minicart')
                            .trigger('click');
                    }
                    $('script[src*="ltkSendSCA.js"]').attr('ltk-data-sca', data.SCACart);
                    $('body').trigger('ltkAsyncListenerCustom');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    if (data.removedProjectID) {
                        $('.project-' + data.removedProjectID).remove();
                    }
                    if (data.triggeredProjectID) {
                        var projCountObj = $('.project-' + data.triggeredProjectID).find(
                            '.project-items-count .count'
                        );
                        projCountObj.text(parseInt(projCountObj.text(), 10) - 1);
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos')
                        .empty()
                        .append(data.basket.totals.discountsHtml);
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-available-msg,.bonus-product-button').removeClass(
                            'd-none'
                        );
                        $('.bonus-product-button button').text(
                            $('.bonus-product-button').data('selecttext')
                        );
                        $('.bonus-item-wrapper').addClass('d-none');
                    }
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket, data.projectIncomplete);
                    $('script[src*="ltkSendSCA.js"]').attr('ltk-data-sca', data.SCACart);
                    $('body').trigger('ltkAsyncListenerCustom');
                }

                $('body').trigger('cart:update', data);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('body').on(
        'click',
        '.js-bb-saved-cart-item-qty-control .js-qtyminus-save',
        function (e) {
            e.preventDefault();
            var quantity = parseInt(
                $(this).parent().find('.js-cart-quantity-text-savelater').val(),
                10
            );
            quantity += -1;
            if (quantity < 1) {
                return false;
            }
            $(this).parent().find('.js-cart-quantity-text-savelater').val(quantity);
            $.ajax({
                url: $(this).parent().data('url') + '&qty=' + quantity,
                dataType: 'json',
                success: function (response) {
                    if (response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                },
            });
            return true;
        }
    );
    $('body').on('change', '.js-bb-saved-cart-item-qty-control', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $this = $(this);
        var quantity = parseInt($this.val(), 10);
        $.ajax({
            url: $this.data('action') + '&qty=' + quantity,
            dataType: 'json',
            success: function (response) {
                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
                if (
                    response.success &&
					response.itemObj &&
					response.itemObj.priceTotal
                ) {
                    $this
                        .parents('.product-item')
                        .find('.price-total')
                        .text(response.itemObj.priceTotal);
                }
                if (!response.success) {
                    toastr.error(response.message);
                    $this.val($this.attr('value'));
                }
                $.spinner().stop();
            },
            error: function (errResponse) {
                $.spinner().stop();
            },
        });
        return true;
    });

    // eslint-disable-next-line consistent-return
    $('body').on(
        'keyup mouseup',
        '.line-item-quantity .js-show-sale-max',
        function () {
            var currentValue = parseInt($(this).val(), 10);
            var preSelectQty = parseInt($(this).attr('data-current-qty'), 10);
            if (currentValue <= preSelectQty) {
                preSelectQty = currentValue;
            }
            var maxsale = parseInt($(this).attr('data-max-sale-allowed'), 10);
            if (preSelectQty >= maxsale) {
                $(this).val(maxsale);
                toastr.remove();
                toastr.error('Quantity Limited to ' + maxsale);
                return false;
            }
            return true;
        }
    );

    // eslint-disable-next-line consistent-return
    $('body').on('change', '.quantity-form .quantity', function () {
        $.spinner().start();
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
        };
        url = appendToUrl(url, urlParams);

        // $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.quantity[data-uuid="' + uuid + '"]').attr(
                    'data-current-qty',
                    quantity
                );
                // $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $.spinner().stop();
                if (
                    $(this)
                        .parents('.product-info')
                        .hasClass('bonus-product-line-item') &&
					$('.cart-page').length
                ) {
                    location.reload();
                }
                // var listrakTRigger = reqire('../listrakSCSEvents.js')
                // listrakTRigger();
                // var scriptVars = document.querySelector('script[src*="ltkSendSCA.js"]');
                // if (scriptVars) {
                //     scriptVars.attr('ltk-data-sca', data.SCACart);
                $('script[src*="ltkSendSCA.js"]').attr('ltk-data-sca', data.SCACart);
                $('body').trigger('ltkAsyncListenerCustom');
                // }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            },
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id'),
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        var $form = $(this);
        $form.find('.coupon-missing-error').hide();
        $form.find('.coupon-error-message').empty();
        if (!$form.find('.coupon-code-field').val()) {
            $form.find('.form-control').addClass('is-invalid');
            $form.find('.form-control').attr('aria-describedby', 'missingCouponCode');
            $form.find('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        $form.find('.form-control').removeClass('is-invalid');
        $form.find('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $form.find('.form-control').addClass('is-invalid');
                    $form
                        .find('.form-control')
                        .attr('aria-describedby', 'invalidCouponCode');
                    $form.find('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                } else {
                    $form
                        .find('.coupons-and-promos')
                        .empty()
                        .append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $('body').trigger('promotion:success', data);
                }
                $form.find('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            },
        });
        return false;
    });

    // eslint-disable-next-line consistent-return
    $('body').on('click', '.remove-coupon', function (e) {
        if ($('.checkout-page').length > 0) {
            return false;
        }
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    // eslint-disable-next-line consistent-return
    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        if ($('.checkout-page').length > 0) {
            return false;
        }
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $('body').trigger('promotion:success', data);
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $(function () {
        if (
            $('.cart-page .bonus-product-button').length > 0 &&
			!$('.bonus-product-line-item .bonus-line-item-edit').length
        ) {
            // if bonus product exists then open modal popup
            $('.cart-page .bonus-product-button').trigger('click');
        }
    });
    $('body').on('click', '.bonus-line-item-edit a.edit', function (e) {
        e.preventDefault();
        $('.cart-page .bonus-product-button').trigger('click');
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            },
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on(
        'click',
        '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit',
        function (e) {
            e.preventDefault();

            var editProductUrl = $(this).attr('href');
            getModalHtmlElement();
            fillModalElement(editProductUrl);
        }
    );

    $('body').on('shown.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', function () {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select',
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr(
            'disabled',
            !$('.global-availability', dialog).data('ready-to-order') ||
				!$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer).closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data('available');
                });

            var allReady = $('.product-availability', dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data('ready-to-order');
                });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog)
                .empty()
                .html(
                    allReady ? response.message : response.resources.info_selectforstock
                );
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show')
                .find(response.container)
                .data('pid', response.data.product.id);
            $('.modal.show')
                .find(response.container)
                .find('.product-id')
                .text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data(
            'selected-quantity',
            selectedQuantity
        );
    });

    $('body').on('change', '.options-select', function () {
        var selectedOptionValueId = $(this)
            .children('option:selected')
            .data('value-id');
        $('.modal.show .update-cart-url').data(
            'selected-option',
            selectedOptionValueId
        );
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .val();
        var selectedQuantity = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('selected-quantity');
        var selectedOptionValueId = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('selected-option');
        var uuid = $(this)
            .closest('.cart-and-ipay')
            .find('.update-cart-url')
            .data('uuid');

        var gcAttributes = [];
        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId,
            gcAttributes: JSON.stringify(gcAttributes),
        };

        // if the pdp is a gift certificate page, then add logic to handle the gift certificate fileds
        if ($('.js-giftcert-pdp').length) {
            var isFormValid = $('.js-giftcert-pdp')[0].checkValidity();
            if (!isFormValid) {
                return;
            }
            if (
                $('#giftcert_purchase_recipientEmail').length > 0 &&
				$('#giftcert_purchase_recipientEmail').val().length > 0
            ) {
                gcAttributes.push({
                    Code: 'GC_RecipientEmail',
                    Value: $('#giftcert_purchase_recipientEmail').val(),
                });
            }
            if (
                $('#giftcert_purchase_from').length > 0 &&
				$('#giftcert_purchase_from').val().length > 0
            ) {
                gcAttributes.push({
                    Code: 'GC_SenderName',
                    Value: $('#giftcert_purchase_from').val(),
                });
            }
            if (
                $('#giftcert_purchase_recipient').length > 0 &&
				$('#giftcert_purchase_recipient').val().length > 0
            ) {
                gcAttributes.push({
                    Code: 'GC_RecipientName',
                    Value: $('#giftcert_purchase_recipient').val(),
                });
            }
            if (
                $('#giftcert_purchase_deliveryDate').length > 0 &&
				$('#giftcert_purchase_deliveryDate').val().length > 0
            ) {
                gcAttributes.push({
                    Code: 'GC_DeliveryDate',
                    Value: $('#giftcert_purchase_deliveryDate').val(),
                });
            }
            if (
                $('#giftcert_purchase_message').length > 0 &&
				$('#giftcert_purchase_message').val().length > 0
            ) {
                gcAttributes.push({
                    Code: 'GC_Message',
                    Value: $('#giftcert_purchase_message').val(),
                });
            }
            form.gcAttributes = JSON.stringify(gcAttributes);
        }

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    if (data.isSavedForLater) {
                        window.location.href = data.redirectUrl;
                    } else {
                        $('#editProductModal').modal('hide');

                        $('.coupons-and-promos')
                            .empty()
                            .append(data.cartModel.totals.discountsHtml);
                        updateCartTotals(data.cartModel);
                        updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        updateAvailability(data.cartModel, uuid);
                        updateProductDetails(data, uuid);

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).remove();
                        }

                        validateBasket(data.cartModel);

                        $('body').trigger('cart:update', data);
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        }
    });

    $('body').on('click', '.delete-project-sfl', function (e) {
        $.get($(this).data('action'), function (response) {
            window.location.reload();
        });
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();

    // share your cart functionality
    $('body').on('submit', '.js-share-your-cart', function (e) {
        e.preventDefault();
        var $form = $(this);

        var recaptchaSiteKey = $form.find('button.email-to-cart').data('site-key');
        var formData = $form.serialize();
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha
                .execute(recaptchaSiteKey, { action: 'submit' })
                .then(function (token) {
                    // Add your logic to submit to your backend server here.
                    formData += '&recaptchaToken=' + token;
                    $.ajax({
                        url: $form.attr('action'),
                        type: 'post',
                        data: formData,
                        success: function (data) {
                            if (data.error) {
                                if ('redirectUrl' in data) {
                                    window.location.href = data.redirectUrl;
                                } else {
                                    formValidation($form, data);
                                    $('form.share.cart').trigger('share:error', data);
                                }
                            } else {
                                // eslint-disable-next-line no-alert
                                $('#shareCartModal').modal('hide');
                                alert(data.message);
                            }
                        },
                    });
                });
        });
    });

    /** Yotpo */
    $('body').on('click', '.yotpo-headline', function () {
        $('.yotpo-widget-products-redemption').toggleClass('open');
    });
    /** PromoCode */
    $('body').on('click', '.coupon-header', function () {
        $('.coupons-and-promos').toggleClass('open');
    });
    $(document).ready(function () {
        var gtmData = $('.cart-datalayer').attr('data-gtmdata');
        if (gtmData && gtmData.length) {
            dataLayer.push(JSON.parse(gtmData));
        }
    });

    $('body').on('click', '.js-recommendation-add-to-cart', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).attr('href');
        var qty = 1;
        var urlParams = {
            pid: productID,
            quantity: parseInt(qty, 10),
            options: [],
        };

        // url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'post',
            data: urlParams,
            dataType: 'json',
            success: function (data) {
                $('body').trigger('cart:update', data);
                window.location.href = data.redirectUrl;

                $.spinner().stop();
                return true;
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });
};
