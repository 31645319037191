'use strict';

module.exports = {
    openQuickLogin: function () {
        $('body').on('click', '.js-popup-login-module-trigger', function (e) {
            if (!($('body').find('.js-login-dashboard-module').length > 0)) {
                e.preventDefault();
                $('.js-login-register-module').addClass('show-module');
                $('#loginPanel').addClass('active');
                $('#registerPanel').removeClass('active');
                $('body').addClass('side-panel-open');
                $('#loginPanel input').first().focus();
                $('html, body').css('overflow', 'hidden');
            }
        });
    },
    openQuickdashboard: function () {
        $('body').on('click', '.js-popup-dashboard-module-trigger', function (e) {
            e.preventDefault();
            $('.js-login-dashboard-module').addClass('show-module');
            $('#dashboardPanel').addClass('active');
            $('body').addClass('side-panel-open');
            $('#loginPanel input').first().focus();
            $('html, body').css('overflow', 'hidden');
        });
    },
    closeQuickLogin: function () {
        $('body').on('click', '.js-module-close', function (e) {
            e.preventDefault();
            $(this).closest('.side-panels').removeClass('show-module');
            $('body').removeClass('side-panel-open');
            $('html, body').removeAttr('style');
        });
    },
    openLogin: function () {
        $('body').on('click', '.js-open-login', function (e) {
            e.preventDefault();
            $('#loginPanel').addClass('active');
            $('#registerPanel').removeClass('active');
            $('#loginPanel input').first().focus();
        });
    },
    revealPassword: function () {
        $('body').on('click', '.js-reveal-password', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $parent = $this.parent();
            var $field = $parent.find('.form-control');
            var $type = $field.attr('type');

            if ($type === 'password') {
                $field.attr('type', 'text');
                $this.removeClass('ri-eye-line').addClass('ri-eye-off-line');
            } else {
                $field.attr('type', 'password');
                $this.removeClass('ri-eye-off-line').addClass('ri-eye-line');
            }
        });
    },
    accountOpen: function () {
        $('body').on('click', '.js-account-module-trigger', function (e) {
            e.preventDefault();
            $('.js-account-module').addClass('show-module');
            $('body').addClass('side-panel-open');
            $('html, body').css('overflow', 'hidden');
        });
    },
    addTOEmailList: function () {
        $('body').on('change', '#add-to-email-list', function () {
            $(this).val(false);
            if ($(this).prop('checked')) {
                $(this).val(true);
            }
        });
    },
};
